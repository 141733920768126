.hidden-parent:hover {
  .hideOnHover {
    display: none;
  }
  .hidden {
    display: block;
    transform: scaleY(1);
  }
}

.hidden {
  display: none;
  transition: all 0.4s;
  transform: scaleY(0);
}
